<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="title">{{ info.noticeTitle }}</div>
        <div class="detail">
            <span>{{ info.createTime }}</span>
            <span>访问量：{{ info.readCount || 0 }}</span>
        </div>
        <div class="vHtml" v-html="info.noticeContent ? info.noticeContent.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
        <div class="butt">
            <!--            <span>上一篇</span>-->
            <!--            <span>上一篇</span>-->
        </div>
    </div>
</template>

<script>
import { getNoticeDetails } from '@/api';

export default {
    name: 'NewsDetail',
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '我的消息',
                    to: { path: '/News' },
                },
                {
                    title: '消息详情',
                },
            ],
            info: {},
        };
    },
    created() {
        this.getNoticeDetails();
    },

    methods: {
        getNoticeDetails() {
            getNoticeDetails(this.$route.query.id).then((res) => {
                console.table(res.data);
                this.info = res.data;
            });
        },
    },
};
</script>

<style scoped lang="less">
.title {
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    color: rgba(0, 0, 0, 0.8);
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.detail {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 20px;
    margin: 20px 0 40px 0;
    color: rgba(0, 0, 0, 0.5);
}
.butt {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 20px;
    margin: 70px 0 100px 0;
}
</style>
